import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Tests from '../views/Tests.vue'
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tests',
    name: 'Tests',
    component: Tests
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( async (to, from, next) => {
  if (!store.state.sync.firstSyncCompleted){
    console.log('Loading data ...', store.state.sync.firstSyncCompleted)
    await store.dispatch('firstSync')
  }
  next()
})

export default router
