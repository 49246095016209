import Vue from 'vue'
import $axios from '../../plugins/axios'

export default {
    state: {
        db: {
            // DÉFINIR TOUTES LES COLLECTIONS à SYNCHRONISER
            // test_pharmacie: {},
            pharmacies: {}
        },
        isSyncing: false,
        syncToken: {},
        firstSyncCompleted: false,
    },
    getters: {
        db: state => state.db,
        syncToken: state => new URLSearchParams(state.syncToken).toString(),
    },
    actions: {
        firstSync({ commit, state, dispatch }) {
            return new Promise((resolve) => {
                Object.keys(state.db).forEach( coll => {
                    commit('setSyncToken', [coll, -1])
                })
                dispatch('sync').then( () => {
                    // TODO RELATIVISER LE LIEN
                    var source = new EventSource("http://localhost:8081/sse");
                    source.onmessage = function(e) {
                        console.log(e.data)
                        if (e.data == "update"){
                            dispatch('sync');
                        }
                    };
                    resolve()
                    console.log('Données loadées')
                    commit('completeFirstSync');
                }).catch( e => {
                    // alert('Erreur avec la synchronisation des données: ' + e)
                    console.log(e)
                })
            })
        },
        sync({ commit, getters }) {
            return new Promise((resolve, reject) => {
                commit('startSync')
                $axios.get('/sync?' + getters.syncToken)
                    .then(resp => {
                        commit('sync', resp.data.sync)
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally( () => commit('endSync'))
            })
        },
    },
    mutations: {
        setSyncToken (state, payload) {
            Vue.set(state.syncToken, payload[0], payload[1])
        },
        startSync (state) {
            state.isSyncing = true;
        },
        endSync (state) {
            state.isSyncing = false;
        },
        completeFirstSync (state) {
            // Vue.set(state.syncToken, payload[0], payload[1])
            state.firstSyncCompleted = true;
        },
        sync(state, payload) {
            console.log(payload)
            Object.keys(payload).forEach(coll => {
                state.syncToken[coll] = payload[coll]['timestamp'];

                payload[coll]['delete'].forEach( id => {
                    Vue.delete(state.db[coll], id)
                })  

                payload[coll]['insertOrUpdate'].forEach( row => {
                    Vue.set(state.db[coll], row[payload[coll]['id']], row)
                })

            })
        },
    },

  }