<template>
  <v-container>
    <v-row class="text-center">
      Hello World
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data: () => ({
    }),
  }
</script>
