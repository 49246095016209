import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'pass': 'testpassword',
    'params': JSON.stringify({"sexe" : "F"})
  }
})

export default axiosInstance;