import Vue from 'vue'
import Vuex from 'vuex'
import sync from './modules/sync'

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    sync
  },
  state: {},
  getters: {},
  actions: {}
})