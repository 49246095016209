<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-btn to="/" text>
        <span>Home</span>
      </v-btn>

      <v-btn to="/tests" text>
        <span>Tests</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
  }),
};
</script>
